<template>
  <div
    class="w-100 mx-0 d-flex flex-column align-center"
    style="min-width: 200px;"
  >
    <div class="w-100 align-center d-flex flex-column pa-5">
      <agency-message-email-section v-if="category == 'Email'" />
      <agency-message-sms-section v-else-if="category == 'SMS'" />
      <agency-message-history-section v-else-if="category == 'History'" />
    </div>
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
  </div>
</template>
<script>
import { mapState } from "vuex";
import AgencyMessageEmailSection from "./AgencyMessageEmailSection.vue";
import AgencyMessageSmsSection from "./AgencyMessageSmsSection.vue";
import AgencyMessageHistorySection from "./AgencyMessageHistorySection.vue";

export default {
  components: {
    AgencyMessageEmailSection,
    AgencyMessageSmsSection,
    AgencyMessageHistorySection
  },
  data() {
    return {
      loading: false,
      errorMessage: null,
      snackbar: false,
      category: ""
    };
  },
  computed: {
    ...mapState("auth", {
      profileType: "type"
    })
  },
  watch: {
    "$route.query.category": function(newValue) {
      this.category = newValue;
    }
  },
  mounted() {
    this.category = this.$route.query.category;
  }
};
</script>
