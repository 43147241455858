<template>
  <div class="w-100">
    <div class="d-flex flex-column mt-3">
      <v-card
        class="w-100 mb-5"
        v-for="log in logs"
        :key="log._id"
        @click="goToDetails(log)"
      >
        <v-card-text class="d-flex pb-0 justify-between">
          <div class="d-flex mr-2">
            <div class="mr-2 blue-font-color">{{ log.type }}:</div>
            <div class="app-bold-font">{{ log.to.join(", ") }}</div>
          </div>
          <div>{{ formattedDate(log.createdAt) }}</div>
        </v-card-text>
        <v-card-title>{{ log.subject }}</v-card-title>

        <v-card-text>{{ extractContent(log.content) }}</v-card-text>
      </v-card>
      <v-snackbar v-model="snackbar">
        {{ snackMessage }}
      </v-snackbar>
    </div>
  </div>
</template>
<script>
import dateFormat from "dateformat";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {},
  data() {
    return {
      loading: false,
      snackbar: false,
      snackMessage: null,
      errorMessage: null,
      errorDelete: null,
      errorDeleteMessage: null
    };
  },
  methods: {
    ...mapActions({
      getAllLogs: "messagelog/fetchAllLogs"
    }),
    extractContent(s) {
      var span = document.createElement("span");
      span.innerHTML = s;
      return span.textContent || span.innerText;
    },
    formattedDate(dateStr) {
      var date = new Date(dateStr);
      var tDay = dateFormat(this.convertTZ(date, this.timezone), "DDDD");
      if (tDay == "Today") {
        return dateFormat(this.convertTZ(date, this.timezone), "hh:MM tt");
      }
      return dateFormat(this.convertTZ(date, this.timezone), "DDD htt");
    },
    convertTZ(date, tzString) {
      if (!date) return "";
      return new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString(
          "en-US",
          {
            timeZone: tzString ? tzString : "America/New_York"
          }
        )
      );
    },
    goToDetails(log) {
      this.$router.push({
        name: "agency-message",
        query: { category: log.type, _id: log._id }
      });
    },
    showSnackMessage(message) {
      this.snackbar = true;
      this.snackMessage = message;
    }
  },
  computed: {
    ...mapGetters({
      profile: "auth/getProfile",
      logs: "messagelog/getAllLogs",
      timezone: "usersetting/getTimeZone"
    })
  },
  watch: {
    profile(newValue) {
      this.email = newValue.email;
      this.phone = newValue.phone;
    }
  },
  mounted() {
    this.loading = true;
    this.getAllLogs()
      .then(() => {
        this.loading = false;
      })
      .catch(error => {
        this.loading = false;
        console.log(error.response.data.message);
      });
  }
};
</script>
<style lang="scss"></style>
